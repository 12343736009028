// translations.js

export const translations = {
    en: {
      // User info
      user: "User",
      level: "Level",
      
      // Header
      energyFree: "ENERGY FREE",
      days: "d",
      hours: "h",
      minutes: "m",
      seconds: "s",
  
      // User stats
      earnPerTap: "Earn per tap",
      coinsToLevelUp: "Coins to level up",
      profitPerHour: "Profit per hour",
  
      // Balance
      balance: "Balance",
  
      // Button and actions
      tap: "Tap",
      boost: "Boost",
  
      // Level info
      bronze: "Bronze",
      silver: "Silver",
      gold: "Gold",
      platinum: "Platinum",
      diamond: "Diamond",
      epic: "Epic",
      legendary: "Legendary",
      master: "Master",
      grandmaster: "Grandmaster",
      lord: "Lord",
  
      // Navigation
      friends: "Friends",
      tasks: "Tasks",
      dailyRewards: "Daily Rewards",
      gemMining: "Gem Mining",
      leaderboard: "Leaderboard",
      gemExchange: "Gem Exchange",
      gem1: "Gem 1",
      gem2: "Gem 2",
      gem3: "Gem 3",
      exchange: "Exchange",
  
      // Tasks
      joinTelegramChannel: "Join Our Telegram Channel",
      telegramChannelDescription: "Subscribe to the Space Animals channel on Telegram for the latest Pixelverse insights and get 100,000 Coins.",
      followInstagram: "Follow Our Instagram",
      instagramDescription: "Follow our Instagram account for exclusive content and earn 100,000 Coins.",
      inviteFriends: "Invite 3 Friends",
      inviteFriendsDescription: "Invite 3 friends to join Space Animals and receive a gift of 500,000 Coins!",
      dailyTasks: "Daily Tasks",
      yourTasks: "Your Tasks",
      dailyTasksComingSoon: "Daily Tasks Coming Soon",
      whatNeedToDo: "What need to do?",
      reward: "Reward",
      start: "Start",
      goToChannel: "Go to channel",
      completed: "Completed",
      featured: "Featured",
      coins: "{amount} coins",
  
      // Loading
      loading: "Loading...",
  
      // Unsupported platform
      unsupportedPlatform: "Wow Wow SpaceAnimal! 📱✨ To unlock the magic, open our app through the Telegram mobile app and let the adventure begin!",
  
      // AppBar
      main: "Main",
      gems: "Gems",
      boosts: "Boosts",

      // Referral
      referral: "REFERRAL",
      inviteFriendsButton: "INVITE FRIENDS",
      earnedFromReferrals: "Earned from referrals",
      score5PercentOfYourBuddies: "Score 5% of your buddies",
      myReferrals: "MY REFERRALS",
      noReferralsYet: "You Don't Have Referrals Yet",
      telegramUserIdNotFound: "Telegram user ID not found",
      failedToGenerateInviteLink: "Failed to generate invite link",
      inviteLinkCopied: "Invite link copied to clipboard!",
      unableToCopyInviteLink: "Unable to copy invite link",
      copyLinkManually: "To invite friends, copy the link and share it manually",
      failedToFetchInvitedFriends: "Failed to fetch invited friends",
      fetchError: "An error occurred. Please try again.",
      
      // бустеры
availableBoosts: "Available Boosts",
earnPerTapBoost: "Earn Per Tap Boost",
passiveIncomeBoost: "Passive Income Boost",
increaseEarnPerTap: "Increase your earn per tap",
increasePassiveIncome: "Increase your passive income",
whatDoesThisBoostDo: "What does this boost do?",
notEnoughCoins: "Not enough coins ({balance}/{price})",
energyBoostLimitReached: "Energy Boost Limit Reached",
maximumBoostsReached: "Maximum boosts reached",
activateBoost: "Activate Boost",
  
      // Other
      switchToRussian: "Switch to Russian",
      joinTelegramChannelDescription: "Join our Telegram channel to complete this task and earn your reward!",
      openTelegramChannel: "Open Telegram Channel",
      subscribeToTelegramFirst: "Please subscribe to our Telegram channel first!",
      failedToCheckSubscription: "Failed to check subscription. Please try again.",
      errorOccurred: "An error occurred. Please try again.",
      taskAlreadyCompleted: "This task has already been completed!",
      notEnoughInvitedFriends: "You've invited {invitedFriends} friend(s). Invite {remaining} more to claim this reward!",
      taskCompletedEarnedCoins: "Task completed! You've earned {points} coins!",
      failedToCompleteTask: "Failed to complete task. Please try again.",

      failedToFetchUserToken: "Failed to fetch user token",
      failedToFetchUserData: "Failed to fetch user data",
      failedToLoadUserData: "Failed to load user data. Please try again.",
      energyRecharged: "Energy Recharged! Your clicks have been reset to the maximum!",
      errorResettingClicks: "An error occurred while resetting your clicks.",
      earnPerTapBoosted: "Earn Per Tap boosted! Now earning {amount} per tap.",
      failedToBoostEarnPerTap: "Failed to boost earn per tap. Please try again.",
      passiveIncomeBoosted: "Passive income boosted! Now earning {amount} per hour.",
      failedToBoostPassiveIncome: "Failed to boost passive income. Please try again.",
      price: "Price",
      usage: "Usage",
      hour: "hour",

      // leaderboard
userIdNotFound: "User ID not found",
error: "Error",
failedToFetchLeaderboard: "Failed to fetch leaderboard",
weeklyReferrer: "WEEKLY REFERRER",
winThisWeek: "Win this week",
refs: "refs",
you: "You",

explore: "Explore",
balanceLeaderboard: "Balance Leaderboard",
gemLeaderboard: "Gem Leaderboard",
realRank: "Real Rank",

categories: "Categories",
mansion: "Mansion",
mansionDescription: "Upgrade your luxurious home.",
footballClub: "Space Club",
footballClubDescription: "Manage your own team.",
skinShop: "Skin Shop",
skinShopDescription: "Customize your character",
comingSoon: "Coming Soon",
confirmOrder: "Are you sure you want to order {item}?",
skinShopComingSoon: "This feature is not available yet. Stay tuned!",
orderConfirmation: "You can continue with your order or cancel it.",
cancel: "Cancel",
ok: "OK",
confirm: "Confirm",

errorLoadingUpgrades: "Error loading upgrades",
perHour: "per hour",
topGemCollectors: "Top Gem Collectors",
maxLevelReached: "Max level reached",
upgradeYourFootballClub: "Upgrade Your Football Club",
confirmUpgrade: "Are you sure you want to upgrade {name}?",
topBalances: "Top Balances",
market: "Market",
upgradeDescription: "This upgrade will increase your hourly income by {boost}",



    },
    ru: {
      // Информация о пользователе
user: "User",
level: "Level",

errorLoadingUpgrades: "Error loading upgrades",
perHour: "per hour",
market: "Shop",
topGemCollectors: "Top Gem Collectors",
topBalances: "Top Rich",
maxLevelReached: "Max Level Reached",
upgradeYourFootballClub: "Upgrade Your Football Club",
confirmUpgrade: "Are you sure you want to upgrade {name}?",
upgradeDescription: "This upgrade will increase your hourly income by {boost}",
explore: "Explore",
balanceLeaderboard: "Balance Leaderboard",
gemLeaderboard: "Gem Leaderboard",
realRank: "Real Rank",

categories: "Categories",
mansion: "Mansion",
mansionDescription: "Upgrade your luxury home.",
footballClub: "Space Club",
footballClubDescription: "Manage your team.",
skinShop: "Skin Shop",
skinShopDescription: "Customize your character",
comingSoon: "Coming Soon",
confirmOrder: "Are you sure you want to order {item}?",
skinShopComingSoon: "This feature is not yet available. Stay tuned!",
orderConfirmation: "You can continue with your order or cancel it.",
cancel: "Cancel",
ok: "OK",
confirm: "Confirm",
      
// Header
energyFree: "UNLIMITED",
days: "d",
hours: "h",
minutes: "m",
seconds: "s",

// User statistics
earnPerTap: "Earnings per tap",
coinsToLevelUp: "To the next level",
profitPerHour: "Profit per hour",
  
// Balance
balance: "Balance",

// Button and actions
tap: "Tap",
boost: "Boost",
coins: "{amount} coins",

// Information about levels
bronze: "Bronze",
silver: "Silver",
gold: "Gold",
platinum: "Platinum",
diamond: "Diamond",
epic: "Epic",
legendary: "Legendary",
master: "Master",
grandmaster: "Grandmaster",
lord: "Lord",
  
// Navigation
friends: "Friends",
tasks: "Tasks",
dailyRewards: "Daily Rewards",
gemMining: "Gem Mining",
leaderboard: "Leaderboard",
gemExchange: "Gem Exchange",
gem1: "Gem 1",
gem2: "Gem 2",
gem3: "Gem 3",
exchange: "Exchange",

userIdNotFound: "User ID not found",
error: "Error",
failedToFetchLeaderboard: "Failed to Fetch Leaderboard",
weeklyReferrer: "WEEKLY REFERRER",
winThisWeek: "Win This Week",
refs: "Ref",
you: "You",
  
// Tasks
joinTelegramChannel: "Join our Telegram channel",
telegramChannelDescription: "Follow Space Animals Telegram channel for the latest Pixelverse news and get 100,000 coins.",
followInstagram: "Follow our Instagram",
instagramDescription: "Follow our Instagram account for exclusive content and get 100,000 coins.",
inviteFriends: "Invite 3 friends",
inviteFriendsDescription: "Invite 3 friends to join Space Animals and get a gift of 500,000 coins!",
dailyTasks: "Daily tasks",
yourTasks: "Your tasks",
dailyTasksComingSoon: "Daily tasks coming soon",
whatNeedToDo: "What do you need to do?",
reward: "Reward",
start: "Start",
goToChannel: "Go to channel",
completed: "Done",
featured: "Recommended",
  
// Loading
loading: "Loading...",

// Unsupported platform
unsupportedPlatform: "Wow Wow Wow, SpaceAnimals! 📱✨ To unlock the magic, open our app via the Telegram mobile app and let the adventure begin!",

      // бустеры
availableBoosts: "Available Boosts",
earnPerTapBoost: "Earnings Boost Per Tap",
passiveIncomeBoost: "Passive Income Boost",
increaseEarnPerTap: "Increase your earnings per tap",
increasePassiveIncome: "Increase your passive income",
whatDoesThisBoostDo: "What does this boost do?",
notEnoughCoins: "Not enough coins ({balance}/{price})",
energyBoostLimitReached: "Energy Boost Limit Reached",
maximumBoostsReached: "Maximum Boosts Reached",
activateBoost: "Activate Boost",
  
      // AppBar
main: "Main",
gems: "Mine",
boosts: "Accelerate",

      // Реферальная программа
referral: "REFERRAL PROGRAM",
inviteFriendsButton: "INVITE FRIENDS",
earnedFromReferrals: "Earned from referrals",
score5PercentOfYourBuddies: "Earn 5% of your friends' earnings",
myReferrals: "MY REFERRALS",
noReferralsYet: "You don't have any referrals yet",
telegramUserIdNotFound: "Telegram user ID not found",
failedToGenerateInviteLink: "Failed to generate invite link",
inviteLinkCopied: "Invite link copied to clipboard!",
unableToCopyInviteLink: "Failed to copy invite link",
copyLinkManually: "To invite friends, copy the link and share it manually",
failedToFetchInvitedFriends: "Failed to get the list of invited friends",
fetchError: "An error occurred. Please try again.",
  
// Other
switchToEnglish: "Switch to English",
joinTelegramChannelDescription: "Join our Telegram channel to complete this task and get a reward!",
openTelegramChannel: "Open Telegram channel",
subscribeToTelegramFirst: "Please subscribe to our Telegram channel first!",
failedToCheckSubscription: "Failed to check your subscription. Please try again.",
errorOccurred: "An error occurred. Please try again.",
taskAlreadyCompleted: "This task has already been completed!",
notEnoughInvitedFriends: "You have invited {invitedFriends} friend(s). Invite {remaining} more to get a reward!",
taskCompletedEarnedCoins: "Task completed! You have earned {points} coins!",
failedToCompleteTask: "Task failed to complete. Please try again again.",


      // В объект ru добавьте:
failedToFetchUserToken: "Failed to fetch user token",
failedToFetchUserData: "Failed to fetch user data",
failedToLoadUserData: "Failed to load user data. Please try again.",
energyRecharged: "Energy restored! Your clicks have been reset to maximum!",
errorResettingClicks: "An error occurred while resetting your clicks.",
earnPerTapBoosted: "Earnings per tap increased! You now earn {amount} per tap.",
failedToBoostEarnPerTap: "Failed to increase earnings per tap. Please try again.",
passiveIncomeBoosted: "Passive income increased! You now earn {amount} per hour.",
failedToBoostPassiveIncome: "Failed to increase passive income. Please try again.",
price: "Price",
usage: "Usage",
hour: "hour",
    }
  };
  
  // Обновленная функция getTranslation
  export const getTranslation = (lang, key) => {
    if (!lang || !key) {
      console.warn(`Missing lang or key in getTranslation: lang=${lang}, key=${key}`);
      return key || '';
    }
  
    if (!translations[lang]) {
      console.warn(`Language "${lang}" not found in translations`);
      return key;
    }
  
    if (!translations[lang][key]) {
      console.warn(`Translation for key "${key}" not found in language "${lang}"`);
      return key;
    }
  
    return translations[lang][key];
  };